import React from 'react'
import * as styles from '../styles/hero.module.css'

export default function HeroHeadline(props) {
    return (
        <div className={styles.smartheroheadline}>
            <h1>{props.h1}</h1>
            <p>{props.sub}</p>
        </div>
    )
}
