import React from 'react'
import * as styles from '../styles/sectionheader.module.css'

export default function Sectionheader(props) {  
    return (
        <div className="col-1">
            <div className={styles.sectionheader}>
                <h2 >{props.name}</h2>
            </div>
        </div>
        
        
    )
}